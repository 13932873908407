<template>
  <v-container class="pa-0">
    <v-row>
      <v-col>
        <v-btn :to="{name: 'sites'}" text width="100%">
          <v-icon class="me-2">mdi-store</v-icon>
          {{ $vuetify.lang.t('$vuetify.pages.more.sites') }}
          <v-spacer/>
          <v-icon v-if="$vuetify.rtl">mdi-chevron-left</v-icon>
          <v-icon v-else>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
      <v-col>
        <v-btn :to="{name: 'profile'}" text width="100%">
          <v-icon class="me-2">mdi-account</v-icon>
          {{ $vuetify.lang.t('$vuetify.pages.more.profile') }}
          <v-spacer/>
          <v-icon v-if="$vuetify.rtl">mdi-chevron-left</v-icon>
          <v-icon v-else>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
      <v-col>
        <v-btn :to="{name: 'settings'}" text width="100%">
          <v-icon class="me-2">mdi-cog</v-icon>
          {{ $vuetify.lang.t('$vuetify.pages.more.settings') }}
          <v-spacer/>
          <v-icon v-if="$vuetify.rtl">mdi-chevron-left</v-icon>
          <v-icon v-else>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider/>
  </v-container>
</template>

<script>
export default {
  name: 'KurccMorePage'
}
</script>
